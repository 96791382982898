<template>
  <div id="app">
    <div class="logo-container">
      <img src="@/assets/logo.svg" alt="Logo" class="logo" />
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 100px; /* Adjust the size of the logo */
  height: 100px; /* Adjust the size of the logo */
}
</style>